header {
  nav {
    li {
      list-style: none;
    }
    ul {
      margin: 0;
      padding: 0 25px 0 15px;
    }
    a {
      text-decoration: none;
    }
  }

  .toggle a {
    color: $lightText;
  }

  .item {
    a {
      color: $lightText;
      font-size: 1.1rem;
      text-transform: uppercase;
      &:hover {
        color: mix(black, $lightText, 20%);
      }
    }
  }
  
  ul.menu {
    display: grid;
    grid-template-columns: 1fr repeat(5, max-content);
    align-items: center;
    grid-auto-flow: column;
    gap: 30px;
  }
  
  .logo img {
    width: 188px;
    position: relative;
    top: 4px;
  }
  
  .toggle {
    display: none;
  }
}

nav ul {
  background: $darkBackground;
}

.hero nav ul {
  padding: 15px 20px 0px 15px;
  background: none;
}

@media screen and (max-width: 960px) {
  header {
    ul.menu {
      grid-template-columns: 1fr  min-content ;
      align-items: center;
      grid-auto-flow: row;
      gap: 0rem;
    }
  
    .logo {
      font-size: 1.4rem;
      grid-row: 1 / span 1;
    }

    .toggle {
      display: block;
      font-size: 20px;
      grid-row: 1 / span 1;
    }

    .item {
      outline: 1px solid mix(black, $lightText, 20%);
      display: none;
      text-align: center;
      background: $darkBackground;
      a {
        display: block;
        padding: 12px;
        font-size: 1.1rem;
      }
    }

    .active .item {
      display: block;
    }

    ul.menu li:nth-child(3) {
      grid-column: 1 / span 2;
      grid-row: 2;
    }

    ul.menu li:nth-child(4) {
      grid-column: 1 / span 2;
      grid-row: 3;
    }

    ul.menu li:nth-child(5) {
      grid-column: 1 / span 2;
      grid-row: 4;
    }

    ul.menu li:nth-child(6) {
      grid-column: 1 / span 2;
      grid-row: 5;
    }

    ul.menu li:nth-child(7) {
      grid-column: 1 / span 2;
      grid-row: 6 ;
    }

    ul.menu li:nth-child(8) {
      grid-column: 1 / span 2;
      grid-row: 7 ;
    }
  }
}
