$darkGrey: rgb(50, 50, 50);
$darkBackground: hsl(205, 63%, 20%);
$titleGradientTop: fade-out($darkBackground, 0.05);
$titleGradientBottom: hsla(205.6, 57.1%, 32.9%, 0.92);
$titleText: hsl(205,40%, 82%);
$lightText: hsl(205, 90%, 83%);
$lightBackground: hsl(205, 25%, 90%);
$button: hsl(205, 57%, 70%);
$footerBackground: fade-out($darkBackground, 0.05);
$offWhite: rgb(220, 220, 220);
$mediumGrey: rgba(110, 110, 110, 0.6);

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: 'Open Sans', sans-serif;
  color: $darkGrey;
}

a {
  color: $darkGrey;
}

h1 {
  font-size: 1.4rem;
  text-transform: uppercase;
  color: $darkBackground;
  font-weight: 700;
  margin: 0 0 1.5rem;
}

h2 {
  font-size: 1.3rem;
  color: $darkGrey;
  font-weight: 600;
}

p, ul {
  font-size: 1.2rem;
  line-height: 1.5;
  margin: 0 0 1.5rem;
}

img {
  max-width: 100%;
}

.text-center {
  text-align: center;
}

.wrapper {
  max-width: 96vw;
  min-height: 62vh;
  margin: 0 auto;
}

.hero-wrapper .wrapper {
  min-height: 0;
}

.wrapper.narrow {
  width: 100%;
  padding: 0 2rem;
  max-width: 900px;
}

.wrapper.wide {
  width: 100%;
  padding: 0 2rem;
  max-width: 1400px;
}

.add-margin {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.hero-grid {
  display: grid;
  padding: 2vw 0 0;
  gap: 2vw;
  grid-template-columns: repeat(auto-fit, minmax(455px, 1fr)); 
  a.project {
    font-size: 1.3rem;
    background-size: cover;
    padding-top: 50%;
  }
}

.main-title {
  display: flex;
  background: linear-gradient($titleGradientTop, $titleGradientBottom);
  align-items: center;
  margin: 0 auto;
  width: 100%;
  padding: 3vh 15px;
  grid-template-columns: minmax(0, 1fr);
  .content {
    margin: 0 auto;
  }
  h1, h2 {
    margin: 0 0 2.2rem;
  }
  h1 {
    color: $titleText;
    text-transform: none;
    font-size: 2.2rem;
    line-height: 1.3;
    font-weight: normal;
  }
  h2 {
    color: $titleText;
    font-size: 1.6rem;
    font-weight: normal;
  }
}

a.button {
  font-size: 1.5rem;
  background: $button;
  padding: 10px 15px;
  color: $darkBackground;
  text-decoration: none;
  border-radius: 5px;
  display: inline-block;
  &:hover {
    background: mix(black, $button, 12%);
  }
}

.services-wrapper, .hero-wrapper {
  background: url("../../assets/images/subtle_surface.png") repeat;
}

ul.services {
  max-width: 1340px;
  margin: 0 auto;
  padding: 50px 50px 50px 100px;
  display: grid;
  row-gap: 1.2rem;
  column-gap: 10rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  font-size: 1.5rem;
  list-style: none;
  li::before {
    content: "\2713";
    color: lighten($darkBackground, 15%);
    font-weight: bold;
    display: inline-block; 
    width: 2rem;
    margin-left: -2rem;
  }
}

.project-grid {
  display:grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  img {
    width: 100%;
  }
}

@media screen and (min-width: 2000px) {
  .main-title h1 {
    font-size: 2.5rem;
  }

  .main-title h2 {
    font-size: 2rem;
  }

  .main-title h1, .main-title h2 {
    margin: 0 0 3.5rem;
  }
}

@media screen and (min-width: 1400px) {
  ul.services {
    padding: 75px 50px 75px 100px;
  }
}

@media screen and (max-width: 960px) {
  .wrapper, .wrapper.narrow, .wrapper.wide {
    max-width: 100%;
    padding: 0 1rem;
  }

  .hero-grid {
    padding: 1rem 0 0;
    a.project {
      display: none;
    }
  }

  .project-grid {
    gap: 1rem;
  }

  ul.services {
    max-width: 605px;
    grid-template-columns: 1fr;
    gap: 1.1rem;
    font-size: 1.1rem;
    padding: 40px 40px 40px 60px;
    li::before {
      width: 1.5rem;
      margin-left: -1.5rem;
    }
  }

  .hero {
    height: auto;

    .main-title {
      h1, h2, a {
        margin-bottom: 1.5rem;
      }
      h1 {
        font-size: 1.5rem;
      }
      h2 {
        font-size: 1.3rem;
      }
      a {
        font-size: 1.2rem;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .project-grid, .hero-grid {
    grid-template-columns: 1fr;
  }
}
