.home-projects-grid {
  display: grid;
  padding: 2vw 0;
  gap: 2vw;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
}

a.project {
  font-size: 1.3rem;
  background-size: cover;
  padding-top: 80%;
}

.bait-packaging {
  background: url("../images/bait_packaging_closeup.jpg") center center no-repeat;
}

.roto-mold {
  background: url("../images/roto_mold_small.jpg") center center no-repeat;
}

.roto-mold-model {
  background: url("../images/roto_mold_model.jpg") center center no-repeat;
}

.garden-seed {
  background: url("../images/garden_seed_display.jpg") center center no-repeat;
}

.cartridge-filling {
  background: url("../images/cartridge_filling_system.jpg") center center no-repeat;
}

.cartridge-filling-closeup {
  background: url("../images/cartridge_filling_closeup.jpg") center center no-repeat;
}

.lagoon-pumping {
  background: url("../images/lagoon_pumping_system.jpg") center center no-repeat;
}

.tie-dye {
  background: url("../images/tie_dye_machine.jpg") center center no-repeat;
}

.sheet-feed {
  background: url("../images/sheet_feed_system_small.jpg") center center no-repeat;
}

.magnet-dispenser-systems {
  background: url("../images/magnet_dispenser_detail.jpg") center center no-repeat;
}

.automotive-component-assembly-station {
  background: url("../images/automotive_component_assembly_station.jpg") center center no-repeat;
}

.automotive-component-inspection-station {
  background: url("../images/automotive_component_inspection_station.jpg") center center no-repeat;
}

.large-box-feed-system-refurbishment {
  background: url("../images/large_box_feed_system_refurbishment_detail.jpg") center center no-repeat;
}

.refurbish-pneumatic-paint-booth {
  background: url("../images/refurbish_pneumatically_driven_paint_booth.jpg") center center no-repeat;
}

.high-temperature-oven-lift-system {
  background: url("../images/oven_lift_system.jpg") center center no-repeat;
}

.automate-simulated-stone-mold-production {
  background: url("../images/simulated_stone_mold_production_layout.png") center center no-repeat;
}

.roof-and-door-actuation-systems {
  background: url("../images/roof_and_door_actuation_systems.jpg") center center no-repeat;
}

.quick-change-production-tooling {
  background: url("../images/quick_change_production_tooling.jpg") center center no-repeat;
}

.edge-cleaning-brush {
  background: url("../images/edge_cleaning_brush.jpg") center center no-repeat;
}

.palletizing-robot {
  background: url("../images/palletizing_robot.jpg") center center no-repeat;
}

.therapy-table {
  background: url("../images/therapy_table.jpg") center center no-repeat;
}

.flux-recycling-system {
  background: url("../images/flux_recycling_system.jpg") center center no-repeat;
}

.high-pressure-hydraulic-swivel {
  background: url("../images/high_pressure_hydraulic_swivel.jpg") center center no-repeat;
}

.marine-thruster-system {
  background: url("../images/marine_thruster_system.jpg") center center no-repeat;
}

.marine-hydraulic-power-unit {
  background: url("../images/marine_hydraulic_power_unit.jpg") center center no-repeat;
}

.rovs-and-support-systems {
  background: url("../images/rovs_and_support_systems.jpg") center center no-repeat;
}

.laser-measuring-and-inspection-system {
  background: url("../images/laser_measuring_and_inspection_system.jpg") center center no-repeat;
}

.seed-starter-tray-assembly-system {
  background: url("../images/seed_starter_tray_assembly_system.jpg") center center no-repeat;
}
/* Description text */
.project .description {
  visibility: hidden;
  background-color: $mediumGrey;
  color: #fff;
  text-align: center;
  padding: 5px 0;

  /* Position the description text */
  position: relative;
  display: inline-block;
  width: 100%;
  z-index: 1;

  /* Fade in description */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Show the description text when you mouse over the description container */
.project:hover .description {
  visibility: visible;
  opacity: 1;
}

@media screen and (max-width: 960px) {
  .home-projects-grid {
    padding: 1rem 0;
    gap: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .home-projects-grid {
    display: none;
  }

  .wrapper {
    min-height: 0;
  }
}
