.footer-grid {
  display: grid;
  grid-template-columns: 1fr max-content max-content;
  gap: 6vw;
}

footer  {
  background: $footerBackground;
  padding: 4vh;
  color: white;
  a {
    color: white;
  }
  nav ul {
    margin: 0;
    background: none;
    list-style-type: none;
    padding-left: 0;
    li a {
      font-size: 1.2rem;
      line-height: 2;
    }
  }

  h2 {
    font-size: 1.3rem;
    font-weight: 600;
    margin: 0;
    color: white;
  }

  p {
    margin: 5px 0 30px;
    &.copyright {
      text-align:center;
      margin: 20px 0 0;
    }
  }
}

@media only screen and (max-width: 600px) {
  footer {
    text-align: center;
    nav {
      text-align: center;
    }
  }

  .footer-grid {
    display: block;
  }

  footer p {
    text-align: center;
  }
}

@media only screen and (min-width: 1000px) {
  footer {
    padding: 4vh 8vw;
  }
}

@media only screen and (min-width: 1600px) {
  footer {
    padding: 4vh 10vw;
  }
}

@media only screen and (min-width: 2000px) {
  footer {
    padding: 4vh 14vw;
  }
}
